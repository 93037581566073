import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';
import './ContactUs.css';
import Footer from '../components/Footer';
import contactTopImage from '../Images/Contact/360_F_629996929_I6exkuvf97YvAz1zRDmNAOE8rwpnKyBc.jpg'; // Replace with your image path

const ContactUs = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: 'General',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_n97e9uk', // Replace with your EmailJS Service ID
            'template_wddy7y3', // Replace with your EmailJS Template ID
            formData,
            'CC0s0WX5-OTDGGqX8' // Replace with your EmailJS User ID
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Message sent successfully!');
            setFormData({
                name: '',
                email: '',
                phone: '',
                subject: 'General',
                message: ''
            });
        }).catch((error) => {
            console.error('FAILED...', error);
            alert('Failed to send message. Please try again later.');
        });
    };

    return (
        <div>
            <div className="contact-top-section">
                <img src={contactTopImage} alt={t('Contact Top')} className="contact-top-image" />
                <div className="contact-top-text">
                    <h1>{t('CONTACT US')}</h1>
                    <p>{t('The voyage of a Hiru Ceylon spice exporter.')}</p>
                </div>
                <Navbar />
            </div>
            <div className="contact-form-section">
                <div className="contact-form-box">
                    <h2>{t('Feel free to use this contact form to ask any questions, request a quotation, or inquire about our business. We typically respond to all inquiries within 24 hours.')}</h2>
                    <br />
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <label htmlFor="name">{t('Name')}:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="email">{t('Email')}:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="phone">{t('Phone')}:</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="subject">{t('Subject')}:</label>
                        <select
                            id="subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        >
                            <option value="General">{t('General')}</option>
                            <option value="Request a Quotation">{t('Request a Quotation')}</option>
                        </select>

                        <label htmlFor="message">{t('Message')}:</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="5"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>

                        <button type="submit">{t('Submit')}</button>
                    </form>
                </div>
            </div>
            <div className="location-map-section">
                <div className="map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1984.3854151318252!2d79.8577297658427!3d6.927078795582747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2593d02c7b27f%3A0xe5f4c3c89c0e95d0!2sCinnamon%20Grand%20Colombo!5e0!3m2!1sen!2slk!4v1690113458794!5m2!1sen!2slk"
                        title="Map showing the new location"
                        className="map-frame"
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                    ></iframe>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;